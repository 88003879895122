import React, { useEffect } from "react";
import Webcam from "react-webcam";
import './Camera.css';

function Camera(props){
    let webcam; 
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);

    if(props.activateCamera === true){
        webcam = <Webcam  ref={webcamRef} className="webcam"></Webcam>
    }else{
        webcam = null;
    }


    const capture = React.useCallback(() => {
        setImgSrc(webcamRef.current.getScreenshot());
      }, [webcamRef, setImgSrc]);
    
    useEffect(() => {
        if(props.activateCamera === true){
            const interval = setInterval(() => {
                capture();
            }, 5000);
            return () => {
                clearInterval(interval);
            };
        }  //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activateCamera]);





    useEffect(() => {
        if(imgSrc !== null){
           if(process.env.REACT_APP_FLASK_URL !== null){
            var form = new FormData();
            form.append("file", imgSrc);
            var requestOptions = {
                method: 'POST',
                body: form,
            }
            const url = process.env.REACT_APP_FLASK_URL + "/check_face_exists";
            console.log(url);
            fetch(url, requestOptions).then(response => {
                let data = response.json();
                return data;
            })
            .then(result => {
                props.handleCameraEvent(result.face_exists)
            }).catch(error => {
                console.log('error', error);
            }
            );
           }
        }else{
            console.log("no image taken");
        }//eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imgSrc]);

    return (
       webcam
    )
}

export default Camera;