import './App.css';
import { Container, Navbar, Row, Col} from 'react-bootstrap';
import React from 'react';
import Timer from './Timer';
import SwitchButton from './SwitchButton';

function App() {

  const [timerStyle] = React.useState("SUNSET DRIVE");
  const [activateCamera, setActivateCamera] = React.useState(false);

  function changeCameraStatus(status){
    setActivateCamera(status);
  }

  return (
    <Container>
      <Navbar className="justify-content-center">
        <Navbar.Brand>
          <Col >
            <Row>
            <p className='title'>TIMEY</p>
            </Row>
            <Row>
            <p className='subtitle'>{timerStyle}</p>
            </Row>
             <Row className="cameraSwitch">
               <SwitchButton onSwitch={changeCameraStatus}></SwitchButton>
             </Row>
            </Col>
        </Navbar.Brand>
      </Navbar>
      <Timer  activateCamera={activateCamera}/>
    </Container>
  );
}

export default App;
