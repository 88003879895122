//import boilerplate imports for react
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './Timer.css';
import CarAnimation from './CarAnimation.js';
import Camera from './Camera';
import play from "./assets/play.png";
import pause from "./assets/pause.png";
import tomato_dark from "./assets/tomato_dark.png";
import tomato_light from "./assets/tomato_light.png";
import replay from "./assets/replay.png";



function Timer(props) {


    const [timeRemaining, setTimeRemaining] = React.useState(0);
    const [animationTime , setAnimationTime] = React.useState(0);
    const [isPomodoro , setIsPomodoro] = React.useState(false);
    const [isRunning, setIsRunning] = React.useState(false);
    const [breakTime] = React.useState(5 * 60);
    const [onBreak, setOnBreak] = React.useState(false);
    const [sessionTime, setSessionTime] = React.useState(0);
    const [userLeft , setUserLeft] = React.useState(false); 
    // userLeft is used to keep track of whether pause was triggered by user leaving (so it needs to auto start when they come )
    // or by the the user so no need to autostart/stop

    React.useEffect(() => {
     if (isRunning === true && timeRemaining > 0) {
        const interval = setInterval(() => {
            setTimeRemaining(timeRemaining - 1);
        }, 1000); //1s = 1000ms
        return () => {
            clearInterval(interval);
        };
      }
    if (isRunning === false && timeRemaining > 0) {
        setAnimationTime(timeRemaining);
    }
    if(timeRemaining === 0){
        if(isPomodoro === true){
            if(onBreak === false){
                setTimeRemaining(breakTime);
                setAnimationTime(breakTime );
                setOnBreak(true);
                setUserLeft(false)
            }else{
                setTimeRemaining(sessionTime);
                setAnimationTime(sessionTime);
                setOnBreak(false);
                setUserLeft(true)
            }
        }
        else{
            setIsRunning(false);
            setOnBreak(false);
            setTimeRemaining(0);
            setAnimationTime(0);
            setUserLeft(false);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }}, [isRunning, timeRemaining]);

    


    const handleCameraEvent = (event) => {
        if(onBreak === false){
            if(userLeft === true){
                setIsRunning(event)
            }
        }
    }


  return (
    <div className="completeTimerRow">
        <Row className='animationRow'>
        <Col xs={1} className='buttonColmn'>
                <button className="button_symbol_right" onClick={() =>{
                if(timeRemaining>0){
                    if(isRunning === false && userLeft === false){
                        setTimeRemaining(timeRemaining  - (5 * 60) );
                        setAnimationTime(timeRemaining   - (5 * 60) );
                        setSessionTime(timeRemaining - (5 * 60));
                    }}
                }}>
                -
                </button>
            </Col>
            <Col className='animationColumn' >
                <Row>
                <CarAnimation totalTime={animationTime} isRunning={isRunning} timeRemaining={timeRemaining} onBreak={onBreak} className="animation"></CarAnimation>
                </Row>
                <Row className="playPauseRow">
                <Col>
                        <button className="playPause" onClick={()=> {
                        setIsPomodoro(false);
                        setOnBreak(false);
                        setTimeRemaining(0);
                        setAnimationTime(0);
                        setIsRunning(false);
                        setUserLeft(false)
                        setSessionTime(0);
                        }
                    }>
                        <img src={replay} className="playPauseIcon" alt='reset button'></img>
                        </button>
                     </Col>

                    <Col>
                        <button onClick={() => {setIsRunning(!isRunning)
                                                setUserLeft(!userLeft)}} className="playPause">
                        {isRunning ? <img src={pause} className="playPauseIcon" alt='pause button'></img> : <img src={play}  className="playPauseIcon" alt='play button'></img>}
                        </button>
                     </Col>
                     <Col>
                        <button className="playPause" onClick={()=> setIsPomodoro(!isPomodoro)}>
                        {isPomodoro ? <img src={tomato_dark} className="playPauseIcon" alt='pomodoro button on'></img> : <img src={tomato_light}  className="playPauseIcon"  alt='pomodoro button off'></img>}
                        </button>
                     </Col>

                 </Row>

            </Col>
            <Col xs={1} className='buttonColmn'>
        <button className="button_symbol_left" onClick={() => {
                                                         if (isRunning === false && userLeft === false){
                                                            setTimeRemaining(timeRemaining + (5 * 60));
                                                            setAnimationTime(timeRemaining   + (5 * 60))
                                                            setSessionTime(timeRemaining+ (5 * 60))}
                                                        }
                                                    }>
                                                              +
        </button>
            </Col>
        </Row>
        <Row>
            <Camera activateCamera={props.activateCamera} handleCameraEvent={handleCameraEvent} ></Camera>
        </Row>

    </div>
    );
}

export default Timer;